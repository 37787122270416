// File: CreateMemorial.js

import React, { useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import {
    TextField,
    Button,
    Typography,
    Box,
    Dialog,
    DialogTitle,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Grid,
    Container,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import required styles
import Navbar from './Navbar';
import Steps from './Steps';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PersonIcon from '@mui/icons-material/Person';
import CakeIcon from '@mui/icons-material/Cake';
import EventIcon from '@mui/icons-material/Event';
import CategoryIcon from '@mui/icons-material/Category';
import SaveIcon from '@mui/icons-material/Save';

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

function generateRandomId(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

function CreateMemorial() {
    const [deceasedName, setDeceasedName] = useState('');
    const [deceasedSurname, setDeceasedSurname] = useState('');
    const [deceasedNickname, setDeceasedNickname] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [dateOfDeath, setDateOfDeath] = useState(null);
    const [featuredImage, setFeaturedImage] = useState(null);
    const [category, setCategory] = useState('');
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileExt = file.name.split('.').pop();
            const fileName = `${generateRandomId(10)}.${fileExt}`;
            const filePath = `${fileName}`;

            const { data, error } = await supabase
                .storage
                .from('memorial_assets')
                .upload(filePath, file);

            if (error) {
                console.error('Error uploading image:', error.message);
            } else {
                setFeaturedImage(filePath);
            }
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const memorialId = generateRandomId(10);
        const userId = Cookies.get('userId');

        const { data, error } = await supabase
            .from('memorials')
            .insert([
                {
                    owner_id: userId,
                    deceased_name: deceasedName,
                    deceased_surname: deceasedSurname,
                    deceased_nickname: deceasedNickname,
                    deceased_date_of_birth: dateOfBirth ? dateOfBirth.toISOString().split('T')[0] : null,
                    deceased_date_of_death: dateOfDeath ? dateOfDeath.toISOString().split('T')[0] : null,
                    memorial_Id: memorialId,
                    profile_image: featuredImage,
                    category: category,
                },
            ]);

        if (error) {
            console.error('Error inserting memorial:', error.message);
        } else {
            Cookies.set('memorial_Id', memorialId);
            setOpen(true);
            setTimeout(() => {
                setOpen(false);
                navigate('/About');
            }, 2000);
        }
    };

    return (
        <div>
            <Navbar />
            <Box
                sx={{
                    backgroundImage: `url('/images/background2.jpg')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    minHeight: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 2,
                }}
            >
                <Container maxWidth="md">
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{
                            width: '100%',
                            border: '1px solid black',
                            borderRadius: '5px',
                            padding: 3,
                            bgcolor: 'rgba(255, 255, 255, 0.97)',
                            boxShadow: 3,
                        }}
                    >
                        <Typography variant="h4" component="h2" gutterBottom align="center">
                            Create New Memorial
                        </Typography>
                        <Steps activeStep={1} />

                        {/* Date Fields at the Top */}
                        <Grid container spacing={2} sx={{ mb: 2, justifyContent: 'center' }}>
                            {/* Date of Birth (Centered, Right-Aligned Text) */}
                            <Grid item xs={12} sm={5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <DatePicker
                                    selected={dateOfBirth}
                                    onChange={(date) => setDateOfBirth(date)}
                                    dateFormat="yyyy-MM-dd"
                                    customInput={
                                        <TextField
                                            fullWidth
                                            required
                                            margin="normal"
                                            label="Date of Birth"
                                            InputProps={{
                                                startAdornment: (
                                                    <CakeIcon sx={{ color: 'action.active', mr: 1 }} />
                                                ),
                                            }}
                                            sx={{ textAlign: 'right' }} // Right-align the text
                                        />
                                    }
                                />
                            </Grid>

                            {/* Date of Death (Centered, Left-Aligned Text) */}
                            <Grid item xs={12} sm={5} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <DatePicker
                                    selected={dateOfDeath}
                                    onChange={(date) => setDateOfDeath(date)}
                                    dateFormat="yyyy-MM-dd"
                                    customInput={
                                        <TextField
                                            fullWidth
                                            required
                                            margin="normal"
                                            label="Date of Death"
                                            InputProps={{
                                                startAdornment: (
                                                    <EventIcon sx={{ color: 'action.active', mr: 1 }} />
                                                ),
                                            }}
                                            sx={{ textAlign: 'left' }} // Left-align the text
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>

                        {/* Rest of the Form Fields */}
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="deceasedName"
                                    label="Deceased Name"
                                    name="deceasedName"
                                    autoComplete="deceasedName"
                                    autoFocus
                                    value={deceasedName}
                                    onChange={(event) => setDeceasedName(event.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <PersonIcon sx={{ color: 'action.active', mr: 1 }} />
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="deceasedSurname"
                                    label="Deceased Surname"
                                    name="deceasedSurname"
                                    autoComplete="deceasedSurname"
                                    value={deceasedSurname}
                                    onChange={(event) => setDeceasedSurname(event.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <PersonIcon sx={{ color: 'action.active', mr: 1 }} />
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="deceasedNickname"
                                    label="Deceased Nickname"
                                    name="deceasedNickname"
                                    autoComplete="deceasedNickname"
                                    value={deceasedNickname}
                                    onChange={(event) => setDeceasedNickname(event.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <PersonIcon sx={{ color: 'action.active', mr: 1 }} />
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel id="category-label">Category</InputLabel>
                                    <Select
                                        labelId="category-label"
                                        id="category"
                                        value={category}
                                        label="Category"
                                        onChange={(event) => setCategory(event.target.value)}
                                        required
                                        startAdornment={
                                            <CategoryIcon sx={{ color: 'action.active', mr: 1 }} />
                                        }
                                    >
                                        <MenuItem value="personal">Personal</MenuItem>
                                        <MenuItem value="religious figure">Religious Figure</MenuItem>
                                        <MenuItem value="politician">Politician</MenuItem>
                                        <MenuItem value="singer">Singer</MenuItem>
                                        <MenuItem value="actor">Actor</MenuItem>
                                        <MenuItem value="author">Author</MenuItem>
                                        <MenuItem value="academic">Academic</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="featured-image-upload"
                                    type="file"
                                    onChange={handleImageUpload}
                                />
                                <label htmlFor="featured-image-upload">
                                    <Button
                                        variant="contained"
                                        component="span"
                                        fullWidth
                                        startIcon={<CloudUploadIcon />}
                                        sx={{ mt: 2, width: '50%', mx: 'auto' }}
                                    >
                                        Upload Featured Image
                                    </Button>
                                </label>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<SaveIcon />}
                                    sx={{ mt: 2, width: '50%', mx: 'auto', bgcolor: 'purple', '&:hover': { bgcolor: 'purple.dark' } }}
                                >
                                    Save and Continue
                                </Button>
                            </Grid>
                        </Grid>
                        <Dialog autoHideDuration={2000} open={open} onClose={() => setOpen(false)}>
                            <DialogTitle>Memorial Created Successfully</DialogTitle>
                        </Dialog>
                    </Box>
                </Container>
            </Box>
        </div>
    );
}

export default CreateMemorial;