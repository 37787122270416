import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Box,
} from '@mui/material';
import Cookies from 'js-cookie';
import { createClient } from '@supabase/supabase-js';
import { useNavigate } from 'react-router-dom';

// Supabase credentials
const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

const Rose = ({ onRoseAdded }) => {
    const [buttonLabel, setButtonLabel] = useState('Leave A Rose');
    const [roseAdded, setRoseAdded] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [open, setOpen] = useState(false);
    const [formOpen, setFormOpen] = useState(false); // Dialog for non-logged-in users
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const navigate = useNavigate();

    const RoseIcon = '/images/rose.png';

    useEffect(() => {
        const memorialId = Cookies.get('memorialId');
        const userId = Cookies.get('userId');

        if (memorialId && userId) {
            const fetchMemorialData = async () => {
                const { data, error } = await supabase
                    .from('memorials')
                    .select('roses')
                    .eq('memorial_Id', memorialId)
                    .single();

                if (error) {
                    console.error('Error fetching memorial data:', error);
                    return;
                }

                if (data && data.roses) {
                    const roses = data.roses;
                    const userRose = roses.find((rose) => rose.userId === userId);

                    if (userRose) {
                        setButtonLabel('Rose Added');
                        setRoseAdded(true);
                    }
                }
            };

            fetchMemorialData();
        }
    }, []);

    const handleAddRose = async () => {
        if (roseAdded) return;

        const memorialId = Cookies.get('memorialId');
        const userId = Cookies.get('userId');

        if (!userId) {
            setFormOpen(true); // Open the form dialog if not logged in
            return;
        }

        // Logged-in user logic
        const { data: userData, error: userError } = await supabase
            .from('Users')
            .select('name, surname, profile_picture')
            .eq('id', userId)
            .single();

        if (userError) {
            console.error('Error fetching user data:', userError);
            return;
        }

        const { name, surname, profile_picture } = userData;

        const newRose = {
            userId,
            name,
            surname,
            profile_picture,
        };

        await updateRoses(memorialId, newRose);
    };

    const handleFormSubmit = async () => {
        const memorialId = Cookies.get('memorialId');

        const newRose = {
            userId: null, // No user ID for non-logged-in users
            name,
            surname,
            profile_picture: null, // No profile picture for non-logged-in users
        };

        await updateRoses(memorialId, newRose);
        setFormOpen(false);
        setName('');
        setSurname('');
        setButtonLabel('Rose Added');
        setRoseAdded(true);
    };

    const updateRoses = async (memorialId, newRose) => {
        const { data, error } = await supabase
            .from('memorials')
            .select('roses')
            .eq('memorial_Id', memorialId)
            .single();

        if (error) {
            console.error('Error fetching memorial data:', error);
            return;
        }

        let updatedRoses = data.roses ? [...data.roses, newRose] : [newRose];

        const { error: updateError } = await supabase
            .from('memorials')
            .update({ roses: updatedRoses })
            .eq('memorial_Id', memorialId);

        if (updateError) {
            console.error('Error updating roses:', updateError);
        } else {
            setButtonLabel('Rose Added');
            setRoseAdded(true);
            onRoseAdded(); // Notify parent component that a rose has been added
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleFormClose = () => {
        setFormOpen(false);
    };

    return (
        <>
            <Button
                variant="contained"
                onClick={handleAddRose}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                style={{
                    backgroundColor: 'white',
                    color: 'black',
                    width: hovered ? '50%' : '50px',
                    height: '50px',
                    transition: 'width 0.3s ease',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: hovered ? 'space-between' : 'center',
                    padding: hovered ? '0 16px' : '0',
                    cursor: roseAdded ? 'default' : 'pointer',
                }}
            >
                <img src={RoseIcon} alt="Rose Icon" style={{ width: '24px', height: '24px' }} />
                {hovered && <Box ml={1}>{buttonLabel}</Box>}
            </Button>

            {/* Form dialog for non-logged-in users */}
            <Dialog open={formOpen} onClose={handleFormClose}>
                <DialogTitle>Leave a Rose</DialogTitle>
                <DialogContent>
                    <DialogContentText>Please enter your name and surname to leave a rose.</DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Name"
                        fullWidth
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Surname"
                        fullWidth
                        variant="outlined"
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleFormClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleFormSubmit} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Rose;