import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import {
    TextField,
    Button,
    Typography,
    Box,
    Dialog,
    DialogTitle,
    Grid,
    Container,
} from '@mui/material';
import Navbar from './Navbar';
import Edit_steps from './Edit_steps';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CakeIcon from '@mui/icons-material/Cake';
import EventIcon from '@mui/icons-material/Event';

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

function Edit_memorial_info() {
    const [deceasedName, setDeceasedName] = useState('');
    const [deceasedSurname, setDeceasedSurname] = useState('');
    const [deceasedNickname, setDeceasedNickname] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [dateOfDeath, setDateOfDeath] = useState('');
    const [featuredImage, setFeaturedImage] = useState(null);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchMemorialInfo() {
            const userId = Cookies.get('userId');
            const memorialId = Cookies.get('memorialId');
            if (userId && memorialId) {
                try {
                    const { data, error } = await supabase
                        .from('memorials')
                        .select('*')
                        .eq('owner_id', userId)
                        .eq('memorial_Id', memorialId)
                        .single();
                    if (error) {
                        throw error;
                    }
                    if (data) {
                        const { deceased_name, deceased_surname, deceased_nickname, deceased_date_of_birth, deceased_date_of_death, profile_image } = data;
                        setDeceasedName(deceased_name || '');
                        setDeceasedSurname(deceased_surname || '');
                        setDeceasedNickname(deceased_nickname || '');
                        setDateOfBirth(deceased_date_of_birth || '');
                        setDateOfDeath(deceased_date_of_death || '');
                        setFeaturedImage(profile_image || null);
                    }
                } catch (error) {
                    console.error('Error fetching memorial info:', error.message);
                }
            } else {
                console.error('Missing userId or memorialId cookies');
            }
        }
        fetchMemorialInfo();
    }, []);

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileExt = file.name.split('.').pop();
            const fileName = `${generateRandomId(10)}.${fileExt}`;
            const filePath = `${fileName}`;

            const { data, error } = await supabase
                .storage
                .from('memorial_assets')
                .upload(filePath, file);

            if (error) {
                console.error('Error uploading image:', error.message);
            } else {
                setFeaturedImage(filePath);
            }
        }
    };

    const handleSaveAndExit = async (event) => {
        event.preventDefault();
        const userId = Cookies.get('userId');
        const memorialId = Cookies.get('memorialId');
        if (userId && memorialId) {
            try {
                const { data, error } = await supabase
                    .from('memorials')
                    .update({
                        deceased_name: deceasedName,
                        deceased_surname: deceasedSurname,
                        deceased_nickname: deceasedNickname,
                        deceased_date_of_birth: dateOfBirth,
                        deceased_date_of_death: dateOfDeath,
                        profile_image: featuredImage,
                    })
                    .eq('owner_id', userId)
                    .eq('memorial_Id', memorialId);
                if (error) {
                    throw error;
                }
                setOpen(true);
                setTimeout(() => {
                    setOpen(false);
                    navigate('/Memorial_list');
                }, 2000);
            } catch (error) {
                console.error('Error updating memorial info:', error.message);
            }
        } else {
            console.error('Missing userId or memorialId cookies');
        }
    };

    const handleSave = async (event) => {
        event.preventDefault();
        const userId = Cookies.get('userId');
        const memorialId = Cookies.get('memorialId');
        if (userId && memorialId) {
            try {
                const { data, error } = await supabase
                    .from('memorials')
                    .update({
                        deceased_name: deceasedName,
                        deceased_surname: deceasedSurname,
                        deceased_nickname: deceasedNickname,
                        deceased_date_of_birth: dateOfBirth,
                        deceased_date_of_death: dateOfDeath,
                        profile_image: featuredImage,
                    })
                    .eq('owner_id', userId)
                    .eq('memorial_Id', memorialId);
                if (error) {
                    throw error;
                }
                setOpen(true);
                setTimeout(() => {
                    setOpen(false);
                }, 2000);
            } catch (error) {
                console.error('Error updating memorial info:', error.message);
            }
        } else {
            console.error('Missing userId or memorialId cookies');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const userId = Cookies.get('userId');
        const memorialId = Cookies.get('memorialId');
        if (userId && memorialId) {
            try {
                const { data, error } = await supabase
                    .from('memorials')
                    .update({
                        deceased_name: deceasedName,
                        deceased_surname: deceasedSurname,
                        deceased_nickname: deceasedNickname,
                        deceased_date_of_birth: dateOfBirth,
                        deceased_date_of_death: dateOfDeath,
                        profile_image: featuredImage,
                    })
                    .eq('owner_id', userId)
                    .eq('memorial_Id', memorialId);
                if (error) {
                    throw error;
                }
                setOpen(true);
                setTimeout(() => {
                    setOpen(false);
                    navigate('/Edit_About');
                }, 2000);
            } catch (error) {
                console.error('Error updating memorial info:', error.message);
            }
        } else {
            console.error('Missing userId or memorialId cookies');
        }
    };

    return (
        <div>
            <Navbar />
            <Box
                sx={{
                    backgroundImage: `url('/images/background1.jpg')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    minHeight: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 2,
                }}
            >
                <Container maxWidth="md">
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{
                            width: '100%',
                            border: '1px solid black',
                            borderRadius: '5px',
                            padding: 3,
                            bgcolor: 'rgba(255, 255, 255, 0.97)',
                            boxShadow: 3,
                        }}
                    >
                        <Edit_steps activeStep={1} />
                        <Typography variant="h4" component="h2" gutterBottom align="center">
                            Edit Memorial Info
                        </Typography>

                        {/* Full-Width Fields */}
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="deceasedName"
                                    label="Deceased Name"
                                    name="deceasedName"
                                    autoComplete="deceasedName"
                                    autoFocus
                                    value={deceasedName}
                                    onChange={(event) => setDeceasedName(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="deceasedSurname"
                                    label="Deceased Surname"
                                    name="deceasedSurname"
                                    autoComplete="deceasedSurname"
                                    value={deceasedSurname}
                                    onChange={(event) => setDeceasedSurname(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="deceasedNickname"
                                    label="Deceased Nickname"
                                    name="deceasedNickname"
                                    autoComplete="deceasedNickname"
                                    value={deceasedNickname}
                                    onChange={(event) => setDeceasedNickname(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="dateOfBirth"
                                    label="Date of Birth"
                                    name="dateOfBirth"
                                    autoComplete="dateOfBirth"
                                    value={dateOfBirth}
                                    onChange={(event) => setDateOfBirth(event.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <CakeIcon sx={{ color: 'action.active', mr: 1 }} />
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="dateOfDeath"
                                    label="Date of Death"
                                    name="dateOfDeath"
                                    autoComplete="dateOfDeath"
                                    value={dateOfDeath}
                                    onChange={(event) => setDateOfDeath(event.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <EventIcon sx={{ color: 'action.active', mr: 1 }} />
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="featured-image-upload"
                                    type="file"
                                    onChange={handleImageUpload}
                                />
                                <label htmlFor="featured-image-upload">
                                    <Button
                                        variant="contained"
                                        component="span"
                                        fullWidth
                                        startIcon={<CloudUploadIcon />}
                                        sx={{ mt: 2 }}
                                    >
                                        Update Featured Image
                                    </Button>
                                </label>
                            </Grid>
                        </Grid>

                        {/* Buttons */}
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                sx={{ marginRight: 1 }}
                                onClick={handleSaveAndExit}
                            >
                                Save And Exit
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                sx={{ marginRight: 1 }}
                                onClick={handleSave}
                            >
                                Save
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                            >
                                Save and Continue
                            </Button>
                        </Box>

                        <Dialog autoHideDuration={2000} open={open} onClose={() => setOpen(false)}>
                            <DialogTitle>Memorial Updated Successfully</DialogTitle>
                        </Dialog>
                    </Box>
                </Container>
            </Box>
        </div>
    );
}

export default Edit_memorial_info;