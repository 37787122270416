import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { createClient } from "@supabase/supabase-js";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Navbar_public from "./Navbar_public";
import './ViewMassMemorial.css';
import { ImageList, ImageListItem, Dialog, DialogContent, IconButton, Button, TextField, DialogActions, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CommentIcon from '@mui/icons-material/Comment';
import CandleIcon from '@mui/icons-material/EmojiObjects';

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

function ViewMassMemorial() {
    const { id } = useParams();
    const [memorialData, setMemorialData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [openMedia, setOpenMedia] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState(null);
    const [openTributeDialog, setOpenTributeDialog] = useState(false);
    const [selectedPerson, setSelectedPerson] = useState(null);
    const [tributeMessage, setTributeMessage] = useState("");
    const [rosesCount, setRosesCount] = useState({});

    useEffect(() => {
        const fetchMemorial = async () => {
            const { data, error } = await supabase
                .from("mass_memorials")
                .select("title, description, deceased, cover_image, media")
                .eq("id", id)
                .single();

            if (error) {
                console.error("Error fetching memorial data:", error);
            } else {
                setMemorialData(data);
                // Initialize roses count for each person
                const initialRosesCount = {};
                data.deceased.forEach(person => {
                    initialRosesCount[person.id] = 0; // Assuming each person has a unique id
                });
                setRosesCount(initialRosesCount);
            }
            setLoading(false);
        };

        if (id) {
            fetchMemorial();
        }
    }, [id]);

    const handleOpenMedia = (media) => {
        setSelectedMedia(media);
        setOpenMedia(true);
    };

    const handleCloseMedia = () => {
        setOpenMedia(false);
        setSelectedMedia(null);
    };

    const handleOpenTributeDialog = (person) => {
        setSelectedPerson(person);
        setOpenTributeDialog(true);
    };

    const handleCloseTributeDialog = () => {
        setOpenTributeDialog(false);
        setSelectedPerson(null);
        setTributeMessage("");
    };

    const handleLeaveTribute = () => {
        // TODO: Connect to database to save tribute message
        console.log(`Tribute for ${selectedPerson.name}: ${tributeMessage}`);
        handleCloseTributeDialog();
    };

    const handleLeaveRose = (personId) => {
        // TODO: Connect to database to update roses count
        setRosesCount(prev => ({ ...prev, [personId]: prev[personId] + 1 }));
    };

    if (loading) {
        return (
            <Grid container justifyContent="center" style={{ marginTop: "2rem" }}>
                <CircularProgress />
            </Grid>
        );
    }

    if (!memorialData) {
        return (
            <Grid container justifyContent="center" style={{ marginTop: "2rem" }}>
                <Typography variant="h5" color="error">
                    Memorial not found.
                </Typography>
            </Grid>
        );
    }

    const { title, description, deceased, cover_image, media } = memorialData;

    let mediaItems = [];
    if (media) {
        try {
            mediaItems = typeof media === 'string' ? JSON.parse(media) : media;
        } catch (e) {
            console.error("Error parsing media JSON:", e);
        }
    }

    return (
        <div>
            <Navbar_public />
            <div style={{
                backgroundImage: `url('/images/background3.jpg')`,
                backgroundSize: 'cover',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
            }}>
                {/* Background overlay for better contrast */}
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 0,
                }}></div>

                {/* Single Card Container */}
                <Box sx={{
                    width: { xs: '90%', sm: '85%' },
                    maxWidth: '80vw',
                    margin: '50px auto',
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    borderRadius: '15px',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    zIndex: 1,
                    padding: '2rem',
                }}>
                    <Card sx={{ borderRadius: '15px', boxShadow: 'none' }}>
                        {/* Title Section */}
                        <Box sx={{ padding: "2rem", textAlign: "center" }}>
                            <Typography variant="h3" sx={{ fontWeight: 700, color: '#333', fontFamily: 'Merriweather, serif' }}>
                                {title}
                            </Typography>
                        </Box>

                        {/* Media Gallery */}
                        {mediaItems && mediaItems.length > 0 && (
                            <Box sx={{ marginBottom: '2rem' }}>
                                <Typography variant="h5" sx={{ fontWeight: 600, marginBottom: '1.5rem', textAlign: 'center', fontFamily: 'Merriweather, serif' }}>
                                    Media Gallery
                                </Typography>
                                <Box sx={{
                                    display: 'flex',
                                    gap: '16px',
                                    justifyContent: 'flex-start',
                                    overflowX: 'auto',
                                    paddingBottom: '1rem',
                                }}>
                                    {mediaItems.map((item, index) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                minWidth: '150px',
                                                cursor: 'pointer',
                                                transition: 'transform 0.2s',
                                                '&:hover': {
                                                    transform: 'scale(1.05)',
                                                },
                                            }}
                                            onClick={() => handleOpenMedia(item)}
                                        >
                                            {['jpg', 'jpeg', 'png'].includes(item.type.toLowerCase()) ? (
                                                <img
                                                    src={`${item.preview}`}
                                                    alt={item.name}
                                                    style={{
                                                        width: '150px',
                                                        height: '150px',
                                                        objectFit: 'cover',
                                                        borderRadius: '8px',
                                                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                                                    }}
                                                    loading="lazy"
                                                />
                                            ) : ['mp4', 'mov'].includes(item.type.toLowerCase()) ? (
                                                <img
                                                    src={item.preview || '/path/to/default/video/thumbnail.jpg'}
                                                    alt={item.name}
                                                    style={{
                                                        width: '150px',
                                                        height: '150px',
                                                        objectFit: 'cover',
                                                        borderRadius: '8px',
                                                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                                                    }}
                                                    loading="lazy"
                                                />
                                            ) : null}
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        )}

                        {/* Deceased Data Section */}
                        <Box sx={{ padding: "2rem" }}>
                            <TableContainer component={Paper} sx={{ borderRadius: '15px', overflow: 'hidden' }}>
                                <Table aria-label="memorial-deceased-table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={4} sx={{
                                                fontSize: "2rem",
                                                fontWeight: 700,
                                                fontFamily: 'Merriweather, serif',
                                                color: '#333',
                                                textAlign: 'center',
                                                backgroundColor: '#f5f5f5',
                                                borderBottom: '2px solid #ddd',
                                            }}>
                                                {description}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            {['Name', 'Surname', 'Date of Birth', 'Date of Death', 'Actions'].map((header, index) => (
                                                <TableCell
                                                    key={index}
                                                    sx={{
                                                        fontSize: '1.25rem',
                                                        fontWeight: 600,
                                                        fontFamily: 'Merriweather, serif',
                                                        color: '#333',
                                                        textAlign: 'center',
                                                        backgroundColor: '#f5f5f5',
                                                        borderBottom: '2px solid #ddd',
                                                    }}
                                                >
                                                    {header}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {deceased && deceased.map((person, index) => (
                                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                {[person.name, person.surname, dayjs(person.date_of_birth).format('YYYY-MM-DD'), dayjs(person.date_of_death).format('YYYY-MM-DD')].map((value, idx) => (
                                                    <TableCell
                                                        key={idx}
                                                        sx={{
                                                            fontSize: '1.1rem',
                                                            fontFamily: 'Merriweather, serif',
                                                            color: '#555',
                                                            textAlign: 'center',
                                                            borderBottom: '1px solid #ddd',
                                                        }}
                                                    >
                                                        {value}
                                                    </TableCell>
                                                ))}
                                                <TableCell sx={{ textAlign: 'center' }}>
                                                    <IconButton onClick={() => handleLeaveRose(person.id)}>
                                                        <FavoriteIcon sx={{ color: 'red' }} />
                                                        <Typography variant="body2" sx={{ marginLeft: '4px' }}>
                                                            {rosesCount[person.id]}
                                                        </Typography>
                                                    </IconButton>
                                                    <IconButton onClick={() => handleOpenTributeDialog(person)}>
                                                        <CommentIcon sx={{ color: 'blue' }} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Card>
                </Box>

                {/* Media Viewer Dialog */}
                <Dialog open={openMedia} onClose={handleCloseMedia} maxWidth="md" fullWidth>
                    <DialogContent style={{ position: 'relative', padding: 0 }}>
                        <IconButton
                            aria-label="close"
                            onClick={handleCloseMedia}
                            style={{ position: 'absolute', right: 8, top: 8, color: '#fff', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                        >
                            <CloseIcon />
                        </IconButton>
                        {selectedMedia && ['jpg', 'jpeg', 'png'].includes(selectedMedia.type.toLowerCase()) && (
                            <img
                                src={selectedMedia.url}
                                alt={selectedMedia.name}
                                style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                            />
                        )}
                        {selectedMedia && ['mp4', 'mov'].includes(selectedMedia.type.toLowerCase()) && (
                            <video controls style={{ width: '100%', borderRadius: '8px' }}>
                                <source src={selectedMedia.url} type={`video/${selectedMedia.type}`} />
                                Your browser does not support the video tag.
                            </video>
                        )}
                    </DialogContent>
                </Dialog>

                {/* Tribute Message Dialog */}
                <Dialog open={openTributeDialog} onClose={handleCloseTributeDialog}>
                    <DialogTitle>Leave a Tribute for {selectedPerson?.name}</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Tribute Message"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={tributeMessage}
                            onChange={(e) => setTributeMessage(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseTributeDialog}>Cancel</Button>
                        <Button onClick={handleLeaveTribute} color="primary">Submit</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
}

export default ViewMassMemorial;