import React, { useState, useEffect } from 'react';
import { Typography, Box, Button, Modal, TextField, Pagination } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Navbar2 from './Navbar2';
import { createClient } from '@supabase/supabase-js';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { styled } from '@mui/system';
import ShareModal from './ShareModal';
import Messages_public from './Messages_public';
import Memorial_viewer_home from './Memorial_viewer_home';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    backgroundImage: 'url(/images/hero.jpeg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#fff',
    textAlign: 'center',
    overflow: 'hidden',
    position: 'relative',
  },
  hero: {
    height: '70vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    zIndex: 2,
    paddingTop: '80px',
    padding: '20px',
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      height: '80vh',
      paddingTop: '100px',
    },
  },
  heroTitle: {
    fontSize: '6rem',
    fontWeight: 'bold',
    fontFamily: "'Kaushan Script', cursive !important",
    lineHeight: '1.2',
    [theme.breakpoints.down('md')]: {
      fontSize: '4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
      lineHeight: '1.1',
    },
  },
  heroSubtitle: {
    fontSize: '1.5rem',
    transition: 'opacity 1s ease-in-out',
    opacity: 0,
    display: 'inline-block',
    fontFamily: 'Arial, sans-serif',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  subtitleVisible: {
    opacity: 1,
  },
  memorialSection: {
    padding: '30px 10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 2,
  },
  memorialGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)', // 4 columns per row
    gap: '20px',
    width: '100%',
    maxWidth: '1200px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)', // 2 columns on medium screens
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr', // 1 column on small screens
    },
  },
  memorialHeading: {
    fontSize: '3rem',
    fontWeight: 'bold',
    fontFamily: "'Kaushan Script', cursive !important",
    color: 'black',
    marginBottom: '20px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '20px', // Increased gap for better spacing
    justifyContent: 'center',
    marginBottom: '30px', // Increased margin for better separation
    padding: '20px',
    backgroundColor: 'rgba(255, 255, 255, 0.9)', // Semi-transparent white background
    borderRadius: '10px', // Rounded corners
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow
  },
  filterInput: {
    width: '240px', // Slightly increased width
    height: '50px', // Increased height
    backgroundColor: 'white',
    borderRadius: '8px', // Rounded corners
    border: '1px solid #ccc', // Visible border
    padding: '8px',
    '& .MuiOutlinedInput-root': {
      height: '100%',
      '& fieldset': {
        border: '1px solid #ccc', // Visible border for the input field
      },
      '&:hover fieldset': {
        border: '1px solid #999', // Darker border on hover
      },
      '&.Mui-focused fieldset': {
        border: '2px solid #3f51b5', // Thicker border when focused
      },
    },
    '& .MuiInputLabel-root': {
      transform: 'translate(14px, 14px) scale(1)', // Adjusted for perfect centering
      '&.Mui-focused': {
        transform: 'translate(14px, -6px) scale(0.75)', // Adjust when focused
      },
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)', // Adjusted for perfect centering
    },
    '& .MuiOutlinedInput-input': {
      padding: '12px 14px', // Adjusted padding for better text alignment
    },
  },
  filterButton: {
    height: '50px', // Match the height of the input fields
    padding: '0 30px', // Increased padding for better button size
    borderRadius: '8px', // Rounded corners
    backgroundColor: '#3f51b5', // Primary color
    color: 'white',
    fontSize: '16px',
    fontWeight: 'bold',
    textTransform: 'none', // Prevent uppercase transformation
    '&:hover': {
      backgroundColor: '#303f9f', // Darker shade on hover
    },
  },
  whitePagination: {
    '& .MuiPaginationItem-root': {
      color: 'white', // White pagination controls
    },
  },
}));

const CustomCard = styled(Card)(({ theme }) => ({
  width: '100%', // Full width within the grid cell
  height: '450px',
  boxShadow: '0px 16px 40px rgba(0, 0, 0, 0.4)',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
  },
}));

const CardContentWrapper = styled(CardContent)({
  flex: '1 0 auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '5px 10px 0 10px', // Further reduced padding to minimize empty space
});

const ImageWrapper = styled(Box)({
  flex: '0 1 300px', // Increased height of the image wrapper
  overflow: 'hidden',
  cursor: 'pointer', // Add cursor pointer to indicate clickable
});

const Image = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const ContentWrapper = styled(Box)({
  flex: '1 1 auto',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginBottom: '5px', // Further reduced margin to minimize empty space
});

// Helper function to find the first valid image
const findFirstImage = (media) => {
  if (!media || media.length === 0) return null;
  const validImageExtensions = ['jpg', 'jpeg', 'png', 'webp'];
  const validImage = media.find((item) =>
    validImageExtensions.includes(item.type.toLowerCase()) && item.url
  );
  return validImage ? validImage.url : null;
};

function Home() {
  const classes = useStyles();
  const navigate = useNavigate(); // Initialize useNavigate for navigation
  const [memorials, setMemorials] = useState([]);
  const [filteredMemorials, setFilteredMemorials] = useState([]);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [currentShareUrl, setCurrentShareUrl] = useState('');
  const [currentMemorial, setCurrentMemorial] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [showFirstSubtitle, setShowFirstSubtitle] = useState(true);
  const [filters, setFilters] = useState({
    dateOfBirth: '',
    dateOfDeath: '',
    name: '',
    surname: '',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 8; // 8 memorials per page

  useEffect(() => {
    const intervalId = setInterval(() => {
      setShowFirstSubtitle((prev) => !prev);
    }, 7000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchMemorials = async () => {
      const { data, error } = await supabase
        .from('memorials')
        .select('*')
        .eq('published', true);

      if (error) {
        console.error('Error fetching memorials:', error.message);
      } else {
        setMemorials(data);
        setFilteredMemorials(data); // Initialize filtered memorials with all data
      }
    };

    fetchMemorials();
  }, []);

  const handleOpenModal = (memorial) => {
    setCurrentMemorial(memorial);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setCurrentMemorial(null);
    setOpenModal(false);
  };

  const handleShareClick = (memorialId) => {
    const baseUrl = window.location.origin;
    const shareUrl = `${baseUrl}/View_memorial/${memorialId}`;
    setCurrentShareUrl(shareUrl);
    setOpenShareModal(true);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const applyFilters = () => {
    const filtered = memorials.filter((memorial) => {
      if (filters.dateOfBirth && memorial.deceased_date_of_birth !== filters.dateOfBirth) {
        return false;
      }
      if (filters.dateOfDeath && memorial.deceased_date_of_death !== filters.dateOfDeath) {
        return false;
      }
      if (filters.name && !memorial.deceased_name.toLowerCase().includes(filters.name.toLowerCase())) {
        return false;
      }
      if (filters.surname && !memorial.deceased_surname.toLowerCase().includes(filters.surname.toLowerCase())) {
        return false;
      }
      return true;
    });

    setFilteredMemorials(filtered);
    setCurrentPage(1); // Reset to the first page after applying filters
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Function to handle image click and redirect to the memorial page
  const handleImageClick = (memorialId) => {
    navigate(`/View_memorial/${memorialId}`); // Redirect to the memorial page
  };

  const paginatedMemorials = filteredMemorials.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <div className={classes.root}>
      <Navbar2 />
      <Box className={classes.hero}>
        <Typography variant="h1" className={classes.heroTitle}>
          Welcome to 4EVR
        </Typography>
        <Typography
          variant="h6"
          className={`${classes.heroSubtitle} ${showFirstSubtitle ? classes.subtitleVisible : ''}`}
        >
          Where the whole world finally comes to rest
        </Typography>
        <Typography
          variant="h6"
          className={`${classes.heroSubtitle} ${!showFirstSubtitle ? classes.subtitleVisible : ''}`}
        >
          We are sorry for your deep loss. 4EVR helps you immortalize your loved ones with beautiful resting places
          and music, and by preserving photographs, videos, and voice clips of them. You can share your memorial with
          close family and friends and ask them to leave a tribute.
        </Typography>
      </Box>

      <Box className={classes.memorialSection}>
        <Typography variant="h4" className={classes.memorialHeading}>
          Featured Memorials
        </Typography>

        {/* Filter Inputs */}
        <Box className={classes.filterContainer}>
          <TextField
            name="dateOfBirth"
            type="date"
            label="Date of Birth"
            value={filters.dateOfBirth}
            onChange={handleFilterChange}
            InputLabelProps={{ shrink: true }}
            className={classes.filterInput}
            variant="outlined"
          />
          <TextField
            name="dateOfDeath"
            type="date"
            label="Date of Death"
            value={filters.dateOfDeath}
            onChange={handleFilterChange}
            InputLabelProps={{ shrink: true }}
            className={classes.filterInput}
            variant="outlined"
          />
          <TextField
            name="name"
            label="Name"
            value={filters.name}
            onChange={handleFilterChange}
            className={classes.filterInput}
            variant="outlined"
          />
          <TextField
            name="surname"
            label="Surname"
            value={filters.surname}
            onChange={handleFilterChange}
            className={classes.filterInput}
            variant="outlined"
          />
          <Button variant="contained" onClick={applyFilters} className={classes.filterButton}>
            Apply Filters
          </Button>
        </Box>

        {/* Memorial Grid */}
        <Box className={classes.memorialGrid}>
          {paginatedMemorials.map((memorial) => (
            <CustomCard key={memorial.memorial_Id}>
              <CardContentWrapper>
                <ContentWrapper>
                  <Typography variant="h6" gutterBottom>
                    {memorial.deceased_name} {memorial.deceased_surname}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Date of Birth: {memorial.deceased_date_of_birth}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Date of Death: {memorial.deceased_date_of_death}
                  </Typography>
                </ContentWrapper>
                <ImageWrapper onClick={() => handleImageClick(memorial.memorial_Id)}> {/* Redirect on image click */}
                  {memorial.media && memorial.media.length > 0 ? (
                    <Image
                      src={findFirstImage(memorial.media) || '/images/thumbnail.svg'}
                      alt="Media"
                    />
                  ) : (
                    <Image src="/images/thumbnail.svg" alt="Default Thumbnail" />
                  )}
                </ImageWrapper>
              </CardContentWrapper>
            </CustomCard>
          ))}
        </Box>

        {/* Pagination */}
        <Pagination
          count={Math.ceil(filteredMemorials.length / pageSize)}
          page={currentPage}
          onChange={handlePageChange}
          sx={{ mt: 2 }}
          className={classes.whitePagination} // Apply white pagination styling
        />
      </Box>

      {openModal && currentMemorial && (
        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              maxHeight: '80vh',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: '10px',
              overflowY: 'auto',
              scrollbarWidth: 'none',
              '-ms-overflow-style': 'none',
            }}
          >
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center">
              <Typography variant="h5" gutterBottom>
                {currentMemorial.deceased_name} {currentMemorial.deceased_surname}
              </Typography>
              <Typography variant="body1" paragraph>
                Born: {currentMemorial.deceased_date_of_birth} - Died: {currentMemorial.deceased_date_of_death}
              </Typography>
            </Box>

            {/* Render the Memorial_viewer_public component */}
            <Memorial_viewer_home memorialId={currentMemorial.memorial_Id} />

            {currentMemorial.about && (
              <ReactQuill
                value={currentMemorial.about}
                readOnly={true}
                style={{ height: 'auto', width: '100%' }}
                modules={{ toolbar: false }}
              />
            )}

            {currentMemorial.memorial_Id && (
              <Messages_public memorialId={currentMemorial.memorial_Id} />
            )}
          </Box>
        </Modal>
      )}

      {openShareModal && currentShareUrl && (
        <ShareModal
          open={openShareModal}
          handleClose={() => setOpenShareModal(false)}
          shareUrl={currentShareUrl}
        />
      )}
    </div>
  );
}

export default Home;